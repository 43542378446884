import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ProfileContext = React.createContext();

const ProfileStore = ({ children }) => {
  const [answered, setAnswered] = useState(false);
  const [profile, setProfile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [pdfRetry, setPdfRetry] = useState(0);

  return (
    <ProfileContext.Provider
      value={{
        answered,
        pdfIsLoading,
        pdfRetry,
        pdfUrl,
        profile,
        setAnswered,
        setPdfIsLoading,
        setPdfRetry,
        setPdfUrl,
        setProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ProfileContext, ProfileStore };
