import {
  useState, useEffect, useRef, createRef,
} from 'react';
import PropTypes from 'prop-types';

import { swapElements } from '../../../utils/index';

import AnswerCard from './AnswerCard';
import AnimateAnswerCards from './AnimateAnswerCards';

const Question = ({
  question,
  currentAnswers,
  onQuestionAnswered,
}) => {
  const [orderedAnswers, setOrderedAnswers] = useState(
    currentAnswers.length
      ? question.answers.sort((a, b) => {
        if (
          currentAnswers.indexOf(a.id) === -1
            && currentAnswers.indexOf(b.id) > -1
        ) return 1;
        if (
          currentAnswers.indexOf(a.id) > -1
            && currentAnswers.indexOf(b.id) === -1
        ) return -1;
        if (
          currentAnswers.indexOf(a.id) === -1
            && currentAnswers.indexOf(b.id) === -1
        ) return 0;
        if (currentAnswers.indexOf(a.id) < currentAnswers.indexOf(b.id)) return -1;
        return 1;
      })
      : question.answers,
  );

  // workaround to call `calculateBoundingBoxes` inside AnimateAnswerCards
  // on each scroll change in order to have latest `getBoundingClientRect` values
  const dontAnimate = useRef(true);
  useEffect(() => {
    let isScrolling;
    const reorderQuestionsOnScrollEnd = () => {
      clearTimeout(isScrolling);

      isScrolling = setTimeout(() => {
        dontAnimate.current = true;
        setOrderedAnswers([...orderedAnswers]);
      }, 66);
    };

    document.addEventListener('scroll', reorderQuestionsOnScrollEnd, false);
    return () => {
      document.removeEventListener('scroll', reorderQuestionsOnScrollEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswers]);

  const onAnswerSelect = (answer) => {
    dontAnimate.current = false;
    const answerIndex = orderedAnswers.findIndex((a) => a.id === answer.id);
    const newOrderedAnswers = [...orderedAnswers];
    newOrderedAnswers.splice(answerIndex, 1);
    newOrderedAnswers.splice(currentAnswers.length, 0, answer);
    onQuestionAnswered([...currentAnswers, answer.id]);
    setOrderedAnswers(newOrderedAnswers);
  };

  const onAnswerDeselect = (answer) => {
    dontAnimate.current = false;
    const answerIndex = orderedAnswers.findIndex((a) => a.id === answer.id);
    const newOrderedAnswers = [...orderedAnswers];
    newOrderedAnswers.splice(answerIndex, 1);
    newOrderedAnswers.splice(currentAnswers.length - 1, 0, answer);
    onQuestionAnswered(currentAnswers.filter((id) => id !== answer.id));
    setOrderedAnswers(newOrderedAnswers);
  };

  const onAnswerMoveDown = (answer) => {
    dontAnimate.current = false;
    const answerIndex = orderedAnswers.findIndex((a) => a.id === answer.id);
    const newOrderedAnswers = [...orderedAnswers];
    swapElements(newOrderedAnswers, answerIndex, answerIndex + 1);
    const selectedIndex = currentAnswers.indexOf(answer.id);
    const newSelectedAnswers = [...currentAnswers];
    swapElements(newSelectedAnswers, selectedIndex, selectedIndex + 1);
    onQuestionAnswered(newSelectedAnswers);
    setOrderedAnswers(newOrderedAnswers);
  };

  const onAnswerMoveUp = (answer) => {
    dontAnimate.current = false;
    const answerIndex = orderedAnswers.findIndex((a) => a.id === answer.id);
    const newOrderedAnswers = [...orderedAnswers];
    swapElements(newOrderedAnswers, answerIndex, answerIndex - 1);
    const selectedIndex = currentAnswers.indexOf(answer.id);
    const newSelectedAnswers = [...currentAnswers];
    swapElements(newSelectedAnswers, selectedIndex, selectedIndex - 1);
    onQuestionAnswered(newSelectedAnswers);
    setOrderedAnswers(newOrderedAnswers);
  };

  return (
    <div className="flex flex-col z-10">
      <AnimateAnswerCards dontAnimate={dontAnimate.current}>
        {orderedAnswers.map((answer) => (
          <AnswerCard
            key={answer.id}
            answer={answer}
            selectedAnswers={currentAnswers}
            onAnswerSelect={onAnswerSelect}
            onAnswerDeselect={onAnswerDeselect}
            onAnswerMoveDown={onAnswerMoveDown}
            onAnswerMoveUp={onAnswerMoveUp}
            ref={createRef()}
          />
        ))}
      </AnimateAnswerCards>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.shape().isRequired,
  currentAnswers: PropTypes.arrayOf(PropTypes.number).isRequired,
  onQuestionAnswered: PropTypes.func.isRequired,
};

export default Question;
