import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import usePrevious from '../../../hooks/usePrevious';
import { calculateBoundingBoxes } from '../../../utils';

const AnimateAnswerCards = ({ children, dontAnimate }) => {
  const [boundingBox, setBoundingBox] = useState({});
  const [prevBoundingBox, setPrevBoundingBox] = useState({});
  const prevChildren = usePrevious(children);

  useLayoutEffect(() => {
    setBoundingBox(calculateBoundingBoxes(children));
  }, [children]);

  useLayoutEffect(() => {
    setPrevBoundingBox(calculateBoundingBoxes(prevChildren));
  }, [prevChildren]);

  useEffect(() => {
    const hasPrevBoundingBox = Object.keys(prevBoundingBox).length;

    if (hasPrevBoundingBox && !dontAnimate) {
      React.Children.forEach(children, (child) => {
        const domNode = child.ref.current;
        const firstBox = prevBoundingBox[child.key];
        const lastBox = boundingBox[child.key];
        const changeInY = firstBox.top - lastBox.top;

        if (changeInY) {
          window.requestAnimationFrame(() => {
            // Before the DOM paints, invert child to old position
            domNode.style.transform = `translateY(${changeInY}px)`;
            domNode.style.transition = 'transform 0s';

            window.requestAnimationFrame(() => {
              // After the previous frame, remove
              // the transistion to play the animation
              domNode.style.transform = '';
              domNode.style.transition = 'transform 500ms';
            });
          });
        }
      });
    }
  }, [boundingBox, prevBoundingBox, dontAnimate, children]);

  return children;
};

AnimateAnswerCards.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dontAnimate: PropTypes.bool.isRequired,
};

export default AnimateAnswerCards;
