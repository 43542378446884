import Header from '../../components/Header';
import Content from '../../components/Content';

const Page404 = () => (
  <>
    <Header>
      <h1 className="text-3xl font-bold mb-4">Page Not Found</h1>
    </Header>
    <Content>
      <p>We were not able to find page you are looking for</p>
    </Content>
  </>
);

export default Page404;
