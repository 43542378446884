import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { toast } from 'react-toastify';

import useQuery from '../../hooks/useQuery';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';
import Question from './Question/Question';
import Spinner from '../../components/Spinner';

import { QuestionsContext } from '../../context/QuestionsContext';
import { ThemeContext } from '../../context/ThemeContext';
import { ProfileContext } from '../../context/ProfileContext';
import { ApproachContext } from '../../context/ApproachContext';

import { sendTalentAnswers } from '../../service';

const Questions = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const id = query.get('id');

  const { backgroundColor, textColor } = useContext(ThemeContext);
  const {
    questions,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    answeredQuestions,
    setAnsweredQuestions,
  } = useContext(QuestionsContext);
  const { setProfile, setPdfUrl } = useContext(ProfileContext);
  const { approach } = useContext(ApproachContext);

  useEffect(() => {
    if (questions.length === 0) {
      history.push(`/?id=${id}`);
    }
  }, [questions, history, id]);

  const [currentQuestion, setCurrentQuestion] = useState(
    questions[currentQuestionIndex],
  );

  const currentAnswers = answeredQuestions[currentQuestionIndex] ?? [];

  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const submitTalentAnswers = async () => {
    try {
      setLoadingAnswers(true);
      const data = await sendTalentAnswers(id, answeredQuestions);
      setProfile(data.profile);
      if (data.url) setPdfUrl(data.url);
      setLoadingAnswers(false);
      history.push(`/results?id=${id}`);
    } catch {
      setLoadingAnswers(false);
      toast.warning(
        t(`questions.submit_error_message.${approach}`),
        { toastId: 'talent_answers' },
      );
    }
  };

  const onPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestion(questions[currentQuestionIndex - 1]);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      animateScroll.scrollToTop({ smooth: true, duration: 500 });
    }
  };
  const onNextQuestion = () => {
    const newAnsweredQuestions = [...answeredQuestions];
    newAnsweredQuestions[currentQuestionIndex] = currentAnswers;
    setAnsweredQuestions(newAnsweredQuestions);
    setCurrentQuestion(questions[currentQuestionIndex + 1]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    animateScroll.scrollToTop({ smooth: true, duration: 500 });
  };

  const getCurrentQuestionText = () => {
    if (i18n.language === 'de' && approach === 'informal') {
      return questions[currentQuestionIndex].text_informal;
    }

    return questions[currentQuestionIndex].text;
  };

  return (
    questions.length > 0 && (
      <>
        {loadingAnswers && <Spinner />}
        <Header>
          <h2 className="text-md uppercase mb-2">
            {t(`questions.number.${approach}`, {
              currentNumber: currentQuestionIndex + 1,
            })}
          </h2>
          <h1 className="text-2xl font-bold mb-3">
            {getCurrentQuestionText()}
          </h1>
          {currentQuestionIndex === 0 && (
            <div
              className="absolute -bottom-12 left-0 w-full text-white px-6 lg:px-0"
              style={{
                backgroundColor: '#9AB2BC',
              }}
            >
              <div className="max-w-2xl mx-auto py-5 px-4 lg:px-0">
                <p className="inline-flex items-center">
                  <img src="/images/info.svg" alt="info" className="h-5 mr-3" />
                  {t(`questions.helptext.${approach}`)}
                </p>
              </div>
            </div>
          )}
        </Header>
        <Content>
          <div
            className={`flex flex-col ${
              currentQuestionIndex === 0 ? 'mt-12' : ''
            }`}
          >
            <div className="inline-flex items-center justify-center mb-3">
              <span className="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.175"
                  height="13.916"
                  viewBox="0 0 10.175 13.916"
                >
                  <path
                    id="Path_5958"
                    data-name="Path 5958"
                    d="M5.106-4.187a1.1,1.1,0,0,0-.348-.8L.839-8.917A1.041,1.041,0,0,0,.47-9.149a1.244,1.244,0,0,0-.449-.085,1.244,1.244,0,0,0-.449.085A1.106,1.106,0,0,0-.8-8.917L-4.721-4.987a1.1,1.1,0,0,0-.348.8,1.037,1.037,0,0,0,.305.775,1.031,1.031,0,0,0,.751.3.926.926,0,0,0,.452-.107,1.434,1.434,0,0,0,.348-.266l1.4-1.4L-.363-6.647-.98-7.141-1.1-4.523v5.9a1.192,1.192,0,0,0,.305.858,1.069,1.069,0,0,0,.812.32,1.069,1.069,0,0,0,.812-.32,1.192,1.192,0,0,0,.305-.858v-5.9L1.022-7.141l-.61.494L1.865-4.883l1.385,1.4A1.434,1.434,0,0,0,3.6-3.22a.926.926,0,0,0,.452.107,1.028,1.028,0,0,0,.754-.3A1.043,1.043,0,0,0,5.106-4.187Zm0-6.11a1.028,1.028,0,0,0-.308-.751,1.013,1.013,0,0,0-.748-.311H-4.007a1.02,1.02,0,0,0-.754.311,1.028,1.028,0,0,0-.308.751,1.028,1.028,0,0,0,.308.751,1.02,1.02,0,0,0,.754.311H4.05A1.013,1.013,0,0,0,4.8-9.546,1.028,1.028,0,0,0,5.106-10.3Z"
                    transform="translate(5.069 11.359)"
                    fill="#94a7af"
                    opacity="0.85"
                  />
                </svg>
              </span>
              <span className="uppercase" style={{ color: '#94a7af' }}>
                {t(`questions.most_applies.${approach}`)}
              </span>
              <span className="ml-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.175"
                  height="13.916"
                  viewBox="0 0 10.175 13.916"
                >
                  <path
                    id="Path_5958"
                    data-name="Path 5958"
                    d="M5.106-4.187a1.1,1.1,0,0,0-.348-.8L.839-8.917A1.041,1.041,0,0,0,.47-9.149a1.244,1.244,0,0,0-.449-.085,1.244,1.244,0,0,0-.449.085A1.106,1.106,0,0,0-.8-8.917L-4.721-4.987a1.1,1.1,0,0,0-.348.8,1.037,1.037,0,0,0,.305.775,1.031,1.031,0,0,0,.751.3.926.926,0,0,0,.452-.107,1.434,1.434,0,0,0,.348-.266l1.4-1.4L-.363-6.647-.98-7.141-1.1-4.523v5.9a1.192,1.192,0,0,0,.305.858,1.069,1.069,0,0,0,.812.32,1.069,1.069,0,0,0,.812-.32,1.192,1.192,0,0,0,.305-.858v-5.9L1.022-7.141l-.61.494L1.865-4.883l1.385,1.4A1.434,1.434,0,0,0,3.6-3.22a.926.926,0,0,0,.452.107,1.028,1.028,0,0,0,.754-.3A1.043,1.043,0,0,0,5.106-4.187Zm0-6.11a1.028,1.028,0,0,0-.308-.751,1.013,1.013,0,0,0-.748-.311H-4.007a1.02,1.02,0,0,0-.754.311,1.028,1.028,0,0,0-.308.751,1.028,1.028,0,0,0,.308.751,1.02,1.02,0,0,0,.754.311H4.05A1.013,1.013,0,0,0,4.8-9.546,1.028,1.028,0,0,0,5.106-10.3Z"
                    transform="translate(5.069 11.359)"
                    fill="#94a7af"
                    opacity="0.85"
                  />
                </svg>
              </span>
            </div>

            <Question
              key={currentQuestion.id}
              question={currentQuestion}
              currentAnswers={currentAnswers}
              onQuestionAnswered={(selectedAnswers) => {
                const newAnsweredQuestions = [...answeredQuestions];
                newAnsweredQuestions[currentQuestionIndex] = selectedAnswers;
                setAnsweredQuestions(newAnsweredQuestions);
              }}
            />

            <div className="inline-flex items-center justify-center mt-3">
              <span className="mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.175"
                  height="13.916"
                  viewBox="0 0 10.175 13.916"
                >
                  <path
                    id="Path_5970"
                    data-name="Path 5970"
                    d="M5.106-4.614a1.043,1.043,0,0,0-.3-.775,1.028,1.028,0,0,0-.754-.3.926.926,0,0,0-.452.107,1.434,1.434,0,0,0-.348.266l-1.385,1.4L.412-2.155l.61.494.116-2.618v-5.9a1.194,1.194,0,0,0-.305-.854,1.063,1.063,0,0,0-.812-.323,1.063,1.063,0,0,0-.812.323,1.194,1.194,0,0,0-.305.854v5.9L-.98-1.66l.616-.494L-1.816-3.918l-1.4-1.4a1.434,1.434,0,0,0-.348-.266.926.926,0,0,0-.452-.107,1.031,1.031,0,0,0-.751.3,1.037,1.037,0,0,0-.305.775,1.1,1.1,0,0,0,.348.8L-.8.116A1.106,1.106,0,0,0-.427.348,1.244,1.244,0,0,0,.021.433,1.244,1.244,0,0,0,.47.348,1.041,1.041,0,0,0,.839.116L4.758-3.815A1.1,1.1,0,0,0,5.106-4.614Zm0,6.11A1.028,1.028,0,0,0,4.8.745,1.013,1.013,0,0,0,4.05.433H-4.007a1.02,1.02,0,0,0-.754.311,1.028,1.028,0,0,0-.308.751,1.028,1.028,0,0,0,.308.751,1.02,1.02,0,0,0,.754.311H4.05A1.013,1.013,0,0,0,4.8,2.246,1.028,1.028,0,0,0,5.106,1.5Z"
                    transform="translate(5.069 11.359)"
                    fill="#94a7af"
                    opacity="0.85"
                  />
                </svg>
              </span>
              <span className="uppercase" style={{ color: '#94a7af' }}>
                {t(`questions.least_applies.${approach}`)}
              </span>
              <span className="ml-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.175"
                  height="13.916"
                  viewBox="0 0 10.175 13.916"
                >
                  <path
                    id="Path_5970"
                    data-name="Path 5970"
                    d="M5.106-4.614a1.043,1.043,0,0,0-.3-.775,1.028,1.028,0,0,0-.754-.3.926.926,0,0,0-.452.107,1.434,1.434,0,0,0-.348.266l-1.385,1.4L.412-2.155l.61.494.116-2.618v-5.9a1.194,1.194,0,0,0-.305-.854,1.063,1.063,0,0,0-.812-.323,1.063,1.063,0,0,0-.812.323,1.194,1.194,0,0,0-.305.854v5.9L-.98-1.66l.616-.494L-1.816-3.918l-1.4-1.4a1.434,1.434,0,0,0-.348-.266.926.926,0,0,0-.452-.107,1.031,1.031,0,0,0-.751.3,1.037,1.037,0,0,0-.305.775,1.1,1.1,0,0,0,.348.8L-.8.116A1.106,1.106,0,0,0-.427.348,1.244,1.244,0,0,0,.021.433,1.244,1.244,0,0,0,.47.348,1.041,1.041,0,0,0,.839.116L4.758-3.815A1.1,1.1,0,0,0,5.106-4.614Zm0,6.11A1.028,1.028,0,0,0,4.8.745,1.013,1.013,0,0,0,4.05.433H-4.007a1.02,1.02,0,0,0-.754.311,1.028,1.028,0,0,0-.308.751,1.028,1.028,0,0,0,.308.751,1.02,1.02,0,0,0,.754.311H4.05A1.013,1.013,0,0,0,4.8,2.246,1.028,1.028,0,0,0,5.106,1.5Z"
                    transform="translate(5.069 11.359)"
                    fill="#94a7af"
                    opacity="0.85"
                  />
                </svg>
              </span>
            </div>

            <div
              className="w-full flex flex-col px-8 lg:px-0 lg:flex-row flex-wrap mt-8"
            >
              {currentQuestionIndex > 0 && (
                <div className="w-full order-2 lg:w-1/2 lg:pr-2 lg:order-1">
                  <Button
                    fullWidth
                    backgroundColor={backgroundColor}
                    inverted
                    textColor={textColor}
                    onClick={onPreviousQuestion}
                  >
                    {t(`questions.prev_button.${approach}`)}
                  </Button>
                </div>
              )}
              {currentQuestionIndex === 9 && (
                <div className="w-full mb-4 lg:w-1/2 lg:mb-0 lg:pl-2 order-1">
                  <Button
                    fullWidth
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    disabled={currentAnswers.length < 5}
                    onClick={submitTalentAnswers}
                  >
                    {t(`questions.finish_button.${approach}`)}
                  </Button>
                </div>
              )}
              {currentQuestionIndex < 9 && (
                <div
                  className={`w-full my-4 order-1 ${
                    currentQuestionIndex > 0 ? 'lg:w-1/2 lg:pl-2' : ''
                  } lg:mt-0 lg:order-2`}
                >
                  <Button
                    fullWidth
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    disabled={currentAnswers.length < 5}
                    onClick={onNextQuestion}
                  >
                    {t(`questions.next_button.${approach}`)}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Content>
      </>
    )
  );
};

export default Questions;
