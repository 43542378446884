import PropTypes from 'prop-types';

const Button = ({
  backgroundColor,
  textColor,
  fullWidth,
  inverted,
  disabled,
  onClick,
  children,
  ...rest
}) => (
  <button
    type="button"
    className={`${
      fullWidth ? 'block w-full' : 'inline-flex'
    } items-center px-4 py-3 border-2 border-transparent text-md font-medium rounded shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 ${
      disabled ? 'cursor-not-allowed opacity-50' : ''
    }`}
    style={{
      backgroundColor: inverted ? 'transparent' : backgroundColor,
      color: inverted ? backgroundColor : textColor,
      borderColor: inverted ? backgroundColor : 'transparent',
    }}
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {children}
  </button>
);

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  inverted: false,
  onClick: () => {},
};

Button.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  textColor: PropTypes.string.isRequired,
};

export default Button;
