// eslint-disable-next-line import/prefer-default-export
export const QUESTIONS = [
  {
    id: 1,
    text:
      'Wie handeln Sie, wenn Sie zusammen mit Freunden ein Problem lösen wollen?',
    text_informal: 'Wie handelst du, wenn du mit Freunden ein Problem lösen willst?',
    answers: [
      {
        id: 1,
        title: 'Kommunizieren',
        text: 'Ich spreche oder chatte über meine Ideen und teile mein Wissen.',
      },
      {
        id: 2,
        title: 'Überzeugen',
        text:
          'Ich sorge dafür, dass sich die anderen meiner Meinung anschließen.',
      },
      {
        id: 3,
        title: 'Analysieren',
        text:
          'Ich schaue mir genau an, was die Ursache des Problems sein könnte.',
      },
      {
        id: 4,
        title: 'Unterstützen',
        text: 'Wir unterstützen uns gegenseitig.',
      },
      {
        id: 5,
        title: 'Miteinander',
        text: 'Gemeinsam kommt man leichter zu einer Lösung.',
      },
    ],
  },
  {
    id: 2,
    text: 'Was ist Ihnen für Ihre Zukunft wichtiger?',
    text_informal: 'Was ist dir für deine Zukunft wichtiger?',
    answers: [
      {
        id: 1,
        title: 'Nachhaltigkeit',
        text:
          'Ich möchte dafür sorgen, dass auf Dauer alles Wertvolle geschützt bleibt.',
      },
      {
        id: 2,
        title: 'Auszeichnungen',
        text: ' Ich möchte, dass meine Leistungen anerkannt werden.',
      },
      {
        id: 3,
        title: 'Informationen',
        text:
          'Ich bin auf positive Weise ein neugieriger Mensch und möchte lernen.',
      },
      {
        id: 4,
        title: 'Zugehörigkeit',
        text:
          'Ich möchte in einem Kreis von guten Freunden einfach dazugehören.',
      },
      {
        id: 5,
        title: 'Erfolge',
        text:
          'Ich mag mich gegen andere durchzusetzen, also einfach erfolgreicher sein.',
      },
    ],
  },
  {
    id: 3,
    text:
      'Sie stehen vor wichtigen Veränderungen in Ihrem Leben. Was beeinflusst Ihre Entscheidung?',
    text_informal: 'Du stehst vor wichtigen Veränderungen in deinem Leben. Was beeinflusst deine Entscheidung?',
    answers: [
      {
        id: 1,
        title: 'Logisches Verständnis',
        text: 'Meine Entscheidungen müssen sinnvoll und logisch sein.',
      },
      {
        id: 2,
        title: 'Wirkung auf andere',
        text:
          'Mir ist wichtig, wie meine Freunde und Familie reagieren werden.',
      },
      {
        id: 3,
        title: 'Umsetzung von Plänen',
        text: 'Es muss ein Schritt in die richtige Richtung sein.',
      },
      {
        id: 4,
        title: 'Sachanalysen',
        text:
          'Ich schaue mir kritisch Alternativen an und bewerte, was das Beste für mich ist.',
      },
      {
        id: 5,
        title: 'Konsens',
        text:
          'Wenn andere mit meiner Entscheidung einverstanden sind, werden sie mich unterstützen.',
      },
    ],
  },
  {
    id: 4,
    text: 'Was können Sie besonders gut?',
    text_informal: 'Was kannst Du besonders gut?',
    answers: [
      {
        id: 1,
        title: 'Mich durchsetzen',
        text: 'Auch gegen Widerstände erreiche ich, was ich schaffen wollte.',
      },
      {
        id: 2,
        title: 'Etwas kontrollieren',
        text: 'Ich bin sehr aufmerksam und habe das Wichtige gut im Griff.',
      },
      {
        id: 3,
        title: 'Für Qualität sorgen',
        text: 'Ich arbeite sorgfältig und strebe nach sehr guten Ergebnissen.',
      },
      {
        id: 4,
        title: 'Lösungen finden',
        text:
          'Ich mag ungelöste Probleme, weil ich dann kreativ an Lösungen arbeiten kann.',
      },
      {
        id: 5,
        title: 'Jemanden beraten',
        text:
          'Freunde kommen gerne zu mir, wenn sie Porbleme haben und holen sich meinen Rat.',
      },
    ],
  },
  {
    id: 5,
    text:
      'Wenn Sie in einer angenehmen Umgebung mit Freunden zusammen sind, welche Begriffe treffen dann auf Sie zu?',
    text_informal: 'Wenn Du in einer angenehmen Umgebung mit Freunden zusammen bist, welche Begriffe treffen dann auf Dich zu?',
    answers: [
      {
        id: 1,
        title: 'Extrovertiert',
        text:
          'Ich bin lebhaft und gerne im Mittelpunkt meines Freundeskreises.',
      },
      {
        id: 2,
        title: 'Empathisch',
        text: 'Ich versuche, mich in andere einzufühlen.',
      },
      {
        id: 3,
        title: 'Energisch',
        text:
          'Wenn etwas für mich wichtig ist, vertrete ich meine Meinung klar und kraftvoll.',
      },
      {
        id: 4,
        title: 'Zuverlässig',
        text:
          'Meine Freunde wissen, dass sie sich auf mich verlassen können, wenn sie meine Hilfe brauchen.',
      },
      {
        id: 5,
        title: 'Introvertiert',
        text:
          'Ich höre lieber zu und rede meistens nur dann, wenn ich gefragt werde.',
      },
    ],
  },
  {
    id: 6,
    text:
      'Wovon ist Ihre Kommunikation stärker geprägt, wenn Sie etwas für sich erreichen wollen?',
    text_informal: 'Wovon ist deine Kommunikation stärker geprägt, wenn du etwas für dich erreichen willst?',
    answers: [
      {
        id: 1,
        title: 'Dominanz',
        text:
          'Ich setze mich mit einer überzeugenden, kraftvollen Sprache auch gegen andere durch',
      },
      {
        id: 2,
        title: 'Entschlussfreude',
        text:
          'Ich bin ein spontaner Mensch, der durch seine schnellen Reaktionen auch andere motiviert.',
      },
      {
        id: 3,
        title: 'Rationalität',
        text:
          'Fakten und logische Tatsachen sind immer noch die besten Argumente.',
      },
      {
        id: 4,
        title: 'Empfindungen',
        text:
          'Ich zeige offen, wie ich mich fühle. So verstehen mich die anderen besser und vertrauen mir.',
      },
      {
        id: 5,
        title: 'Besonnenheit',
        text:
          'Ich erreiche viel mehr, wenn ich ruhig und vernünftig erkläre, was mir wichtig ist.',
      },
    ],
  },
  {
    id: 7,
    text:
      'Mit welcher inneren Haltung arbeiten Sie an einer Aufgabe, die Ihnen persönlich sehr wichtig ist?',
    text_informal: 'Mit welcher inneren Haltung arbeitest du an einer Aufgabe, die dir persönlich sehr wichtig ist?',
    answers: [
      {
        id: 1,
        title: 'Hartnäckig',
        text: 'Ich will unbedingt schaffen, was ich mir vorgenommen habe.',
      },
      {
        id: 2,
        title: 'Zurückhaltend',
        text:
          'Ich gehe Schritt für Schritt vor und bin dabei ganz auf meine Arbeit fokussiert.',
      },
      {
        id: 3,
        title: 'Begeisternd',
        text:
          'Ich mag meine Freude an der Aufgabe gerne mit anderen teilen und versuche auch sie  davon zu begeistern.',
      },
      {
        id: 4,
        title: 'Diszipliniert',
        text:
          'Was ich mir vorgenommen habe, erledige ich so, dass mich kaum etwas ablenken kann.',
      },
      {
        id: 5,
        title: 'Einfühlsam',
        text:
          'Ich fühle mich in die Aufgaben ein, bin innerlich ruhig und handle verantwortungsbewusst.',
      },
    ],
  },
  {
    id: 8,
    text:
      'Was ist Ihnen wichtig, wenn Sie zukünftig berufliche Aufgaben übernehmen sollen?',
    text_informal: 'Was ist dir wichtig, wenn du zukünftig berufliche Aufgaben übernehmen sollst?',
    answers: [
      {
        id: 1,
        title: 'Qualität und Innovationen',
        text:
          'Ich möchte etwas Neues herstellen, das in seiner Qualität etwas Besonders darstellt.',
      },
      {
        id: 2,
        title: 'Ordnung und Fachwissen',
        text:
          'Ich bevorzuge klare Regeln, nach denen ich gut und zuverlässig arbeiten kann.',
      },
      {
        id: 3,
        title: 'Koordination und Abstimmung',
        text:
          'Ich organisieren die Zusammenarbeit, sodass gute Leistungen entstehen.',
      },
      {
        id: 4,
        title: 'Motivation von Beteiligten',
        text:
          'Ich möchte gemeinsam mit Freunden oder Kollegen meine eigenen Ideen umsetzen.',
      },
      {
        id: 5,
        title: 'Logische Tatsachen',
        text:
          'Ich möchte an Aufgaben arbeiten, deren Sinn ich nachvollziehen kann.',
      },
    ],
  },
  {
    id: 9,
    text: 'Wie nehmen Sie Ihr Umfeld wahr?',
    text_informal: 'Wie nimmst du dein Umfeld wahr?',
    answers: [
      {
        id: 1,
        title: 'Realistisch',
        text:
          'Ich will die Wirklichkeit realistisch und detailliert wahrnehmen.',
      },
      {
        id: 2,
        title: 'Ganzheitlich',
        text:
          'Ich finde es spannend, dass sich die Dinge wie in einem dahinfließenden Strom verändern.',
      },
      {
        id: 3,
        title: 'Logisch',
        text: 'Ich interessiere mich für die Zusammenhänge und Strukturen.',
      },
      {
        id: 4,
        title: 'Herausfordernd',
        text: 'Ich finde es gut, wenn ich immer wieder neue Chancen entdecke.',
      },
      {
        id: 5,
        title: 'Vergleichend',
        text: 'Meine Erfahrungen sind mir ein wichtiger Wegweiser.',
      },
    ],
  },
  {
    id: 10,
    text: 'Wie sorgen Sie für gute Entscheidungen?',
    text_informal: 'Wie sorgst du für gute Entscheidungen?',
    answers: [
      {
        id: 1,
        title: 'Nachdenken',
        text: 'Ich nutze den Verstand, denke nach und vergleiche Alternativen.',
      },
      {
        id: 2,
        title: 'Fakten',
        text: 'Ich bin auf die konkreten Fakten fokussiert.',
      },
      {
        id: 3,
        title: 'Gefühl',
        text: 'Ich vertraue auf mein Bauchgefühl.',
      },
      {
        id: 4,
        title: 'Bewerten',
        text:
          'Ich suche nach sinnvollen Entscheidungen, die zu meinem Wertesystem passen.',
      },
      {
        id: 5,
        title: 'Intuitiv',
        text: 'Ich vertraue meinen Ideen und intuitiven Einfällen.',
      },
    ],
  },
];
