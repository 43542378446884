import React from 'react';

const calculateBoundingBoxes = (children) => {
  const boundingBoxes = {};

  React.Children.forEach(children, (child) => {
    const domNode = child.ref.current;
    const nodeBoundingBox = domNode.getBoundingClientRect();

    boundingBoxes[child.key] = nodeBoundingBox;
  });

  return boundingBoxes;
};

const swapElements = (array, indexA, indexB) => {
  const temp = array[indexA];

  // eslint-disable-next-line no-param-reassign
  array[indexA] = array[indexB];
  // eslint-disable-next-line no-param-reassign
  array[indexB] = temp;
};

export { calculateBoundingBoxes, swapElements };
