import { API_URL } from '../config';

const fetchTalentData = async (id) => {
  const response = await fetch(`${API_URL}/api/v1/personality/profile/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

const sendTalentAnswers = async (id, asnwers) => {
  const response = await fetch(`${API_URL}/api/v1/personality/profile/${id}`, {
    method: 'POST',
    body: JSON.stringify({ questionnaire: asnwers }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
};

const fetchTalentPdf = async (id) => {
  const response = await fetch(
    `${API_URL}/api/v1/personality/profile/${id}/pdf`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  return response.json();
};

export { fetchTalentData, sendTalentAnswers, fetchTalentPdf };
