import PropTypes from 'prop-types';

const Spinner = ({ absolute }) => (
  <div
    className={`w-full h-full ${
      absolute ? 'absolute' : 'fixed'
    } block top-0 left-0 bg-white opacity-75 z-50`}
  >
    <div
      className="animate-spin ease-linear rounded-full border-8 border-t-8 border-gray-200 h-40 w-40 mx-auto relative top-1/2"
      style={{
        borderBottomColor: '#2392B2',
      }}
    />
  </div>
);

Spinner.defaultProps = {
  absolute: false,
};

Spinner.propTypes = {
  absolute: PropTypes.bool,
};

export default Spinner;
