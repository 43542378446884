import React, { useState } from 'react';
import PropTypes from 'prop-types';

const QuestionsContext = React.createContext();

const QuestionsStore = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);

  return (
    <QuestionsContext.Provider
      value={{
        questions,
        answeredQuestions,
        setQuestions,
        setAnsweredQuestions,
        currentQuestionIndex,
        setCurrentQuestionIndex,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

QuestionsStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { QuestionsStore, QuestionsContext };
