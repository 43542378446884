import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

import { ApproachContext } from '../../context/ApproachContext';

const ResultBars = ({ colorData }) => {
  const { t } = useTranslation();

  const { approach } = useContext(ApproachContext);

  const mappedData = [
    {
      label: t(`chart.enforcement.title.${approach}`),
      value: Number(colorData.red),
      color: '#EC5D6F',
      description: t(`chart.enforcement.description.${approach}`),
    },
    {
      label: t(`chart.integration.title.${approach}`),
      value: Number(colorData.yellow),
      color: '#F39221',
      description: t(`chart.integration.description.${approach}`),
    },
    {
      label: t(`chart.safety.title.${approach}`),
      value: Number(colorData.green),
      color: '#2FAC66',
      description: t(`chart.safety.description.${approach}`),
    },
    {
      label: t(`chart.individuality.title.${approach}`),
      value: Number(colorData.blue),
      color: '#29A6DF',
      description: t(`chart.individuality.description.${approach}`),
    },
    {
      label: t(`chart.understanding.title.${approach}`),
      value: Number(colorData.black),
      color: '#A447CC',
      description: t(`chart.understanding.description.${approach}`),
    },
    {
      label: t(`chart.empaty.title.${approach}`),
      value: Number(colorData.white),
      color: '#F037BF',
      description: t(`chart.empaty.description.${approach}`),
    },
  ];

  return (
    <div>
      {mappedData.map(({
        value, label, color, description,
      }) => (
        <ExpandableBar
          key={color}
          value={value}
          label={label}
          color={color}
          description={description}
        />
      ))}
    </div>
  );
};

const ExpandableBar = ({
  value, label, color, description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="flex flex-col py-3">
      <div className="flex">
        <div className="w-5/12 lg:w-3/12">
          <p
            className="flex items-center justify-end cursor-pointer"
            onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
          >
            <span className="font-semibold">{label}</span>
            <span>
              <img
                src={`/images/${isExpanded ? 'up' : 'down'}-mobile.svg`}
                alt="arrow"
                className="w-4 ml-3"
              />
            </span>
          </p>
        </div>
        <div className="w-7/12 lg:w-9/12 flex items-center pl-5">
          <div
            className="w-full h-1.5 rounded-md"
            style={{
              backgroundColor: '#cad4d7',
            }}
          >
            <div
              className="h-full rounded-md"
              style={{
                width: `${(value / 200) * 100}%`,
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto italic ">
        <Transition
          show={isExpanded}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <p className="max-w-lg mt-4">{description}</p>
        </Transition>
      </div>
    </div>
  );
};

ExpandableBar.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

ResultBars.propTypes = {
  colorData: PropTypes.shape({
    black: PropTypes.string,
    blue: PropTypes.string,
    green: PropTypes.string,
    red: PropTypes.string,
    white: PropTypes.string,
    yellow: PropTypes.string,
  }).isRequired,
};

export default ResultBars;
