import { useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useQuery from '../../hooks/useQuery';
import Spinner from '../../components/Spinner';
import { ThemeContext } from '../../context/ThemeContext';
import { QuestionsContext } from '../../context/QuestionsContext';
import { ProfileContext } from '../../context/ProfileContext';
import { ApproachContext } from '../../context/ApproachContext';

import { QUESTIONS as QUESTIONS_GERMAN } from '../../assets/questions/de';
import { QUESTIONS as QUESTIONS_ENGLISH } from '../../assets/questions/en';

import { fetchTalentData } from '../../service';

const Start = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const { setBackgroundColor, setTextColor } = useContext(ThemeContext);
  const { setQuestions } = useContext(QuestionsContext);
  const { setProfile, setPdfUrl, setAnswered } = useContext(ProfileContext);
  const { setApproach } = useContext(ApproachContext);

  const loading = useRef(false);
  useEffect(() => {
    async function fetchData() {
      if (loading.current) return;

      const id = query.get('id');

      if (!id) history.push('/404');

      try {
        loading.current = true;
        const data = await fetchTalentData(id);
        if (data.status === 'error') {
          history.push('/404');
          return;
        }

        i18n.changeLanguage(data.language);
        if (data.language === 'de') {
          setQuestions(QUESTIONS_GERMAN);
        } else {
          setQuestions(QUESTIONS_ENGLISH);
        }

        setBackgroundColor(data.colour);
        setTextColor(data.text_colour);
        setApproach(data.approach ?? 'formal');

        if (!data.answered && !data.profile) {
          history.push(`/introduction?id=${id}`);
        } else {
          setProfile(data.profile);
          setPdfUrl(data.url);
          setAnswered(data.answered);
          history.push(`/results?id=${id}`);
        }
      } catch (e) {
        history.push('/404');
      }
    }
    fetchData();
  }, [
    i18n,
    history,
    query,
    setBackgroundColor,
    setTextColor,
    setQuestions,
    setProfile,
    setPdfUrl,
    setApproach,
    setAnswered,
  ]);

  return <Spinner />;
};

export default Start;
