import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useMobileDetect from 'use-mobile-detect-hook';

import { fetchTalentPdf } from '../../service';

import useQuery from '../../hooks/useQuery';

import { ThemeContext } from '../../context/ThemeContext';
import { ProfileContext } from '../../context/ProfileContext';
import { ApproachContext } from '../../context/ApproachContext';

import Button from '../../components/Button';
import Header from '../../components/Header';
import Content from '../../components/Content';

import ResultBars from './ResultBars';

// Const
const RETRY_LIMIT = 3;

const Results = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const query = useQuery();
  const id = query.get('id');

  const { backgroundColor, textColor } = useContext(ThemeContext);
  const { approach } = useContext(ApproachContext);

  const {
    answered,
    pdfIsLoading,
    pdfRetry,
    pdfUrl,
    profile,
    setPdfIsLoading,
    setPdfRetry,
    setPdfUrl,
  } = useContext(ProfileContext);

  const handleFetchTalentPdf = async () => {
    try {
      setPdfIsLoading(true);

      const data = await fetchTalentPdf(id);

      setPdfUrl(data.url);
      setPdfIsLoading(false);
    } catch {
      if (pdfRetry < RETRY_LIMIT) {
        setPdfRetry((prevPdfRetry) => prevPdfRetry + 1);
      } else {
        setPdfIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!answered && !profile) {
      history.push(`/?id=${id}`);
    }
  }, [profile, id, history, pdfUrl, pdfRetry, answered]);

  useEffect(() => {
    if (profile && !pdfUrl && pdfRetry < RETRY_LIMIT) {
      handleFetchTalentPdf();
    }
  }, [pdfRetry, pdfUrl]);

  return (
    <>
      {profile && (
        <>
          <Header>
            <h1 className="text-3xl font-bold mb-4">
              {t(`results.title.${approach}`)}
            </h1>
            <p>{t(`results.description.${approach}`)}</p>
          </Header>
          <Content>
            <div className="">
              <h2
                className="font-semibold uppercase"
                style={{
                  color: '#94A7AF',
                }}
              >
                {t(`results.motivation_type.${approach}`)}
              </h2>
              <h2 className="my-5 text-3xl font-semibold">
                {t(`types.${profile.result}.title.${approach}`)}
              </h2>
              <p>{t(`types.${profile.result}.desc.${approach}`)}</p>
            </div>
            <div
              className="fixed lg:static bottom-0 left-0 w-full lg:py-10 z-10"
            >
              <div
                className="max-w-2xl mx-auto p-8 bg-white shadow-inner lg:bg-transparent lg:p-0"
                style={{
                  boxShadow: isMobile ? '0px -5px 20px #00000029' : 'none',
                }}
              >
                <div>
                  {pdfUrl && (
                    <Button
                      fullWidth
                      backgroundColor={backgroundColor}
                      textColor={textColor}
                      onClick={() => window.open(pdfUrl, '_blank')}
                    >
                      {t(`results.view_pdf_button.${approach}`)}
                    </Button>
                  )}
                  {pdfIsLoading && pdfRetry < RETRY_LIMIT && (
                    <p className="inline-flex items-center">
                      <img
                        src="/images/Spinner.gif"
                        alt="spinner"
                        className="h-20"
                      />

                      <span>{t(`results.wait_few_moments.${approach}`)}</span>
                    </p>
                  )}
                  {pdfRetry === RETRY_LIMIT && (
                    <p className="inline-flex items-center">
                      <span>{t(`results.pdf_not_generated.${approach}`)}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Content>
          <Content bgColor="#fff">
            <div className="mt-6 mb-40 lg:mb-0">
              <p className="mb-4 uppercase font-semibold">
                {t(`results.the_basic_needs.${approach}`)}
              </p>
              <ResultBars colorData={profile.colour} />
              <div className="mt-6 max-w-md">
                <p style={{ color: '#94A7AF' }}>
                  {t(`results.graph_hint.${approach}`)}
                </p>
              </div>
            </div>
          </Content>
        </>
      )}

      {answered && !profile && (
        <>
          <Header>
            <h1 className="text-3xl font-bold mb-4">
              {t(`results.title.${approach}`)}
            </h1>
          </Header>
          <Content>
            <div className="">
              <p>{t(`results.results_error.${approach}`)}</p>
            </div>
          </Content>
        </>
      )}
    </>
  );
};

export default Results;
