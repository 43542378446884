import { useState, forwardRef, useEffect } from 'react';
import useMobileDetect from 'use-mobile-detect-hook';
import PropTypes from 'prop-types';

const AnswerCard = forwardRef(
  (
    {
      answer,
      selectedAnswers,
      onAnswerSelect,
      onAnswerDeselect,
      onAnswerMoveDown,
      onAnswerMoveUp,
    },
    ref,
  ) => {
    const [isSelected, setIsSelected] = useState(
      selectedAnswers.indexOf(answer.id) !== -1,
    );
    useEffect(() => {
      setIsSelected(selectedAnswers.indexOf(answer.id) !== -1);
    }, [selectedAnswers, answer]);
    const detectMobile = useMobileDetect();
    const isMobile = detectMobile.isMobile();

    const isFirst = selectedAnswers.indexOf(answer.id) === 0;
    const isLast = selectedAnswers.indexOf(answer.id) === selectedAnswers.length - 1;

    const onUncheck = (e) => {
      e.stopPropagation();
      onAnswerDeselect(answer);
      setIsSelected(false);
    };

    const onCheck = (e) => {
      e.stopPropagation();
      onAnswerSelect(answer);
      setIsSelected(true);
    };

    return (
      <div
        ref={ref}
        className={`w-full flex my-1.5 bg-white rounded shadow-md cursor-pointer ${
          isSelected ? 'z-20' : 'z-10'
        }`}
        onClick={(e) => {
          if (!isSelected) onCheck(e);
          else onUncheck(e);
        }}
      >
        <div
          className="w-2/12 py-6 relative flex justify-center items-center"
        >
          {!isSelected && (
            <button
              type="button"
              className="flex justify-center items-center w-10 h-10 border-2 rounded-full outline-none focus:outline-none"
              style={{
                borderColor: '#94A7AF',
              }}
            >
              <img
                src={`/images/${isMobile ? 'tap' : 'click'}.svg`}
                alt="click"
                className="block touch:hidden"
              />
            </button>
          )}
          {isSelected && (
            <div className="flex justify-center items-center">
              <button
                type="button"
                style={{
                  borderColor: '#94A7AF',
                  backgroundColor: '#6CB33F',
                }}
                className="w-10 h-10 rounded-full outline-none focus:outline-none"
              >
                <span className="text-white">
                  {selectedAnswers.indexOf(answer.id) + 1}
                </span>
              </button>
            </div>
          )}
        </div>
        <div
          className="w-8/12 lg:w-9/12 py-6 pl-2 lg:pl-0"
          style={{
            minHeight: 72,
          }}
        >
          <h3 className="font-semibold">{answer.title}</h3>
          <p>{answer.text}</p>
        </div>
        <div
          className={`w-2/12 lg:w-1/12 relative flex flex-col justify-center items-center ${
            isSelected && selectedAnswers.length > 1 ? 'border-l' : ''
          }`}
          onClick={(e) => {
            if (isSelected) e.stopPropagation();
          }}
        >
          {isSelected && (
            <button
              type="button"
              className={`block py-5 px-2 outline-none focus:outline-none ${
                isFirst ? 'invisible' : 'visible'
              }`}
              onClick={(e) => {
                e.stopPropagation();
                onAnswerMoveUp(answer);
              }}
            >
              <img
                src={`/images/up-${isMobile ? 'mobile' : 'desktop'}.svg`}
                alt="up arrow"
                className="w-6"
              />
            </button>
          )}
          {isSelected && (
            <button
              type="button"
              className={`block py-5 px-2 outline-none focus:outline-none ${
                isLast ? 'invisible' : 'visible'
              }`}
              disabled={isLast}
              onClick={(e) => {
                e.stopPropagation();
                onAnswerMoveDown(answer);
              }}
            >
              <img
                src={`/images/down-${isMobile ? 'mobile' : 'desktop'}.svg`}
                alt="down arrow"
                className="w-6"
              />
            </button>
          )}
        </div>
      </div>
    );
  },
);

AnswerCard.defaultProps = {
  onAnswerSelect: () => {},
  onAnswerDeselect: () => {},
  onAnswerMoveDown: () => {},
  onAnswerMoveUp: () => {},
};

AnswerCard.propTypes = {
  answer: PropTypes.shape().isRequired,
  selectedAnswers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAnswerSelect: PropTypes.func,
  onAnswerDeselect: PropTypes.func,
  onAnswerMoveDown: PropTypes.func,
  onAnswerMoveUp: PropTypes.func,
};

export default AnswerCard;
