import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    const { children } = this.props;
    // render fallback UI
    return (
      <Sentry.ErrorBoundary
        fallback={(
          <div className="max-w-2xl h-screen flex flex-col justify-center mx-auto text-center" onClick={() => Sentry.showReportDialog()}>
            <p style={{ fontSize: '16px' }}>
              {'We\'re sorry — something\'s gone wrong.'}
            </p>
            <p style={{ fontSize: '16px' }}>
              Our team has been notified, but
              {' '}
              <underline>click here</underline>
              {' '}
              fill out a report.
            </p>
          </div>
        )}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
