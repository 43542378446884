import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ThemeContext } from '../context/ThemeContext';
import { ApproachContext } from '../context/ApproachContext';

const Header = ({ children }) => {
  const { t } = useTranslation();
  const { backgroundColor, textColor } = useContext(ThemeContext);
  const { approach } = useContext(ApproachContext);

  return (
    <header
      style={{
        backgroundColor,
        color: textColor,
      }}
      className="relative"
    >
      <div className="max-w-2xl mx-auto py-8 px-8 lg:px-0">
        <div className="mb-5 lg:inline-flex">
          <span className="">
            <svg
              version="1.1"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 170.7 24"
              overflow="visible"
              xmlSpace="preserve"
              width="111.445"
              height="16"
              className="h-5 w-36"
            >
              <g>
                <rect
                  x="129.4"
                  y="0.5"
                  fill={textColor}
                  width="20.3"
                  height="5.1"
                />
                <rect
                  x="123.3"
                  y="9.5"
                  fill={textColor}
                  width="20.3"
                  height="5.1"
                />
                <rect
                  x="117.2"
                  y="18.5"
                  fill={textColor}
                  width="20.3"
                  height="5.1"
                />
                <path
                  fill={textColor}
                  d="M169.8,3.8c-0.6-1.1-1.5-1.9-2.6-2.5c-1.1-0.6-2.4-0.9-4-0.9h-8.6v23.4h5.1v-9.2h3.6c1.5,0,2.8-0.3,4-0.9 c1.1-0.6,2-1.4,2.6-2.5c0.6-1,0.9-2.2,0.9-3.7S170.4,4.8,169.8,3.8z M165.3,8.7c-0.2,0.4-0.5,0.7-1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3 h-3.2V4.8h3.2c0.6,0,1.1,0.1,1.5,0.3c0.4,0.2,0.8,0.5,1,0.9c0.2,0.4,0.3,0.8,0.3,1.3C165.6,7.9,165.5,8.3,165.3,8.7z"
                />
                <polygon
                  fill={textColor}
                  points="117.8,0.3 100.4,0.3 100.4,5.4 106.6,5.4 106.6,23.7 111.7,23.7 111.7,5.4 117.8,5.4"
                />
                <polygon
                  fill={textColor}
                  points="70.3,16.2 63.7,0.3 56.1,0.3 56.1,23.7 61.3,23.7 61.2,6.7 68.3,23.7 75.4,23.7 75.4,0.3 70.3,0.3"
                />
                <path
                  fill={textColor}
                  d="M47.1,1.9c-2-1.3-4.3-1.9-6.9-1.9c-2.6,0-5.3,0.7-7.2,1.9c-2,1.4-3.3,3.1-4.1,5.4c-0.5,1.4-0.7,2.9-0.7,4.7
    c0,1.6,0.2,3.3,0.7,4.7c0.8,2.2,2.1,4.1,4.1,5.4c1.9,1.3,4.6,1.8,7.2,1.8c2.6,0,4.9-0.6,6.8-1.9s3.3-3.1,4.1-5.4
    c0.5-1.4,0.8-2.9,0.8-4.7c0-1.6-0.2-3.2-0.7-4.6C50.4,5.1,49.1,3.2,47.1,1.9z M46.1,15.1c-0.5,1.3-1.2,2.3-2.2,3.1
    c-1,0.7-2.2,1-3.7,1c-1.4,0-2.6-0.4-3.7-1.1c-1-0.7-1.7-1.7-2.2-3.1c-0.3-1-0.5-2-0.5-3.1c0-1.2,0.2-2.2,0.5-3.1
    c0.5-1.3,1.2-2.3,2.2-3.1c1-0.7,2.2-1.1,3.7-1.1c1.4,0,2.6,0.4,3.7,1.1c1,0.7,1.8,1.7,2.2,3.1c0.3,0.9,0.5,1.9,0.5,3.1
    C46.6,13.1,46.4,14.1,46.1,15.1z"
                />
                <rect
                  x="18.6"
                  y="0.3"
                  fill={textColor}
                  width="5.1"
                  height="23.4"
                />
                <polygon
                  fill={textColor}
                  points="5.2,0.3 0,0.3 0,23.7 14.2,23.7 14.4,23.7 14.4,18.9 5.2,18.9"
                />
                <path
                  fill={textColor}
                  d="M89.7,9.5c-3.6-0.5-4.3-1.3-4.3-2.9c0-1.5,1.2-2.6,3.5-2.6s3.5,0.9,3.8,3h4.8c-0.4-4.6-3.4-6.9-8.6-6.9
                  c-5.1,0-8.4,2.9-8.4,6.9c0,4.5,2.4,6.2,8.3,7c3.4,0.6,4.4,1.3,4.4,3.1c0,1.9-1.6,3.1-3.9,3.1c-3.5,0-4.4-1.8-4.6-3.8h-5
                  c0.3,4.5,3.2,7.5,9.6,7.5c5.4,0,8.8-3.3,8.8-7.4C98.1,11.7,95.3,10.2,89.7,9.5z"
                />
              </g>
            </svg>
          </span>
          <div
            className="hidden lg:block mx-6 pb-4 border-l"
            style={{ borderColor: textColor, opacity: 0.4 }}
          />
          <p className="hidden lg:block uppercase tracking-widest">
            {t(`header.questionnaire.${approach}`)}
          </p>
        </div>
        {children}
      </div>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Header;
