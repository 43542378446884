import { ToastContainer } from 'react-toastify';
import Root from './container/Root';

import { ThemeStore } from './context/ThemeContext';
import { QuestionsStore } from './context/QuestionsContext';
import { ProfileStore } from './context/ProfileContext';
import { ApproachStore } from './context/ApproachContext';

import ErrorBoundary from './components/ErrorBoundry';

function App() {
  return (
    <ErrorBoundary>
      <ProfileStore>
        <QuestionsStore>
          <ThemeStore>
            <ApproachStore>
              <Root />
              <ToastContainer />
            </ApproachStore>
          </ThemeStore>
        </QuestionsStore>
      </ProfileStore>
    </ErrorBoundary>
  );
}

export default App;
