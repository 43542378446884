// eslint-disable-next-line import/prefer-default-export
export const QUESTIONS = [
  {
    id: 1,
    text:
      'Please imagine a situation where you have to solve a problem together with other people. How well do the following items describe your actions?',
    answers: [
      {
        id: 1,
        title: 'Communication',
        text: 'I communicate my ideas and information.',
      },
      {
        id: 2,
        title: 'Persuasion',
        text: 'I make sure that others align themselves with my opinion.',
      },
      {
        id: 3,
        title: 'Analysis',
        text: 'I search for possible associations and causations.',
      },
      { id: 4, title: 'Support', text: 'We mutually support each other.' },
      {
        id: 5,
        title: 'Talking to each other',
        text: 'It is easier to find a solution collectively.',
      },
    ],
  },
  {
    id: 2,
    text:
      'When you have achieved something important and are satisfied with yourself, how much do each of the following items mean for you?',
    answers: [
      {
        id: 1,
        title: 'Sustainability',
        text:
          'I have ensured that all valuables are protected in the long run.',
      },
      {
        id: 2,
        title: 'Awards',
        text: 'Other people also acknowledge my accomplishments.',
      },
      {
        id: 3,
        title: 'Information',
        text: 'I have been extensively informed.',
      },
      {
        id: 4,
        title: 'Integration',
        text: 'A good feeling of togetherness satisfies me.',
      },
      {
        id: 5,
        title: 'Success',
        text: 'I am more successful than others (I won).',
      },
    ],
  },
  {
    id: 3,
    text:
      'Imagine you are planning an important change in your life. Which of the following factors influence your decision to which extent?',
    answers: [
      {
        id: 1,
        title: 'Logical comprehension',
        text: 'My decisions have to be reasonable and logical.',
      },
      {
        id: 2,
        title: 'Impact on others',
        text: 'It is important to me how others will react.',
      },
      {
        id: 3,
        title: 'Implementation of plans',
        text: 'It has to be a step into the right direction.',
      },
      {
        id: 4,
        title: 'Analysis',
        text:
          'I critically look at alternatives and evaluate which one is the best.',
      },
      {
        id: 5,
        title: 'Consensus',
        text: 'People who are important to me should agree to my decision.',
      },
    ],
  },
  {
    id: 4,
    text:
      'Please think about your everyday life and the tasks which are associated with it. How strongly are each of the following skills developed in your case?',
    answers: [
      {
        id: 1,
        title: 'Asserting oneself',
        text: 'Even against resistance I achieve what I want.',
      },
      {
        id: 2,
        title: 'Controlling processes',
        text:
          'I am very attentive and have the most important things under control.',
      },
      {
        id: 3,
        title: 'Ensuring quality',
        text: 'I am very critically and thus am good at making improvements.',
      },
      {
        id: 4,
        title: 'Finding solutions',
        text:
          'I like unsolved problems because I can work on creative solutions.',
      },
      {
        id: 5,
        title: 'Consulting someone',
        text: 'People come to me go get my advice.',
      },
    ],
  },
  {
    id: 5,
    text:
      'Please think about a situation in which you are sitting and talking with friends in a comfortable environment. How well do the following items describe you?',
    answers: [
      {
        id: 1,
        title: 'Extroverted',
        text: 'I am lively and enjoy being amidst friends.',
      },
      { id: 2, title: 'Empathetic', text: 'I try to empathize with others.' },
      {
        id: 3,
        title: 'Energetic',
        text:
          'If it is important, I stand by my opinion clearly and forcefully.',
      },
      {
        id: 4,
        title: 'Reliable',
        text: 'I let other realize that they can count on me.',
      },
      {
        id: 5,
        title: 'Introverted',
        text: 'I only speak if something is important to me.',
      },
    ],
  },
  {
    id: 6,
    text:
      'There are situations where one wants to attain something for oneself. Which of the following items are more characteristic of your communication when matters are important to you?',
    answers: [
      {
        id: 1,
        title: 'Dominance',
        text: 'I assert myself against others with a decisive stance.',
      },
      {
        id: 2,
        title: 'Decisiveness',
        text:
          ' I appear convincing because I react quickly and decide spontaneously.',
      },
      {
        id: 3,
        title: 'Rationality',
        text: 'Facts and logical data are still the best argument.',
      },
      {
        id: 4,
        title: 'Emotions',
        text: 'I openly show how I feel. This enables my partners to trust me.',
      },
      {
        id: 5,
        title: 'Reason',
        text:
          'It is about communicating both facts as well as emotions in a an appropriate manner.',
      },
    ],
  },
  {
    id: 7,
    text:
      'Please imagine other people observing you at a task which is means a lot to you. Which of the following impressions are the people more likely to have about you?',
    answers: [
      { id: 1, title: 'Persistent', text: 'I want to assert my goals.' },
      { id: 2, title: 'Reserved', text: 'I am rather calm and cautious.' },
      {
        id: 3,
        title: 'Inspirational',
        text: 'I try to inspire others for my task.',
      },
      {
        id: 4,
        title: 'Disciplined',
        text: 'I am concentrating on my work and cannot be easily distracted.',
      },
      {
        id: 5,
        title: 'Empathetic',
        text: 'What I do should be responsible and fair.',
      },
    ],
  },
  {
    id: 8,
    text:
      'Please imagine a situation in which you can choose a task. How appealing are the following characteristics of the task to you?',
    answers: [
      {
        id: 1,
        title: 'Quality and innovation',
        text: 'I would like to create something new in good quality.',
      },
      {
        id: 2,
        title: 'Order and knowledge',
        text: 'I feel well if I can work well and reliably.',
      },
      {
        id: 3,
        title: 'Coordination and reconciliation',
        text: 'I like to organize and coordinate tasks among each other.',
      },
      {
        id: 4,
        title: 'Motivation of involved parties',
        text: 'I would like to implement my ideas together with a team.',
      },
      {
        id: 5,
        title: 'Logical facts',
        text:
          'It is important to me that everything makes sense and follows a purpose.',
      },
    ],
  },
  {
    id: 9,
    text: 'How do you perceive your environment?',
    answers: [
      {
        id: 1,
        title: 'Realistic',
        text: 'I want to perceive reality realistically and in detail.',
      },
      {
        id: 2,
        title: 'Holistic',
        text:
          'I find it exciting that things are changing like in a flowing stream.',
      },
      {
        id: 3,
        title: 'Logical',
        text: "I'm interested in the inherent logic and structure of things.",
      },
      {
        id: 4,
        title: 'Challenging',
        text: 'I like it when I discover new opportunities again and again.',
      },
      {
        id: 5,
        title: 'Comparative',
        text: 'My experiences are an important guide for me.',
      },
    ],
  },
  {
    id: 10,
    text: 'How do you make good decisions?',
    answers: [
      {
        id: 1,
        title: 'Thinking',
        text: 'I use the mind, think and compare alternatives.',
      },
      { id: 2, title: 'Facts', text: "I'm focused on the concrete facts." },
      { id: 3, title: 'Feeling', text: 'I trust my gut feeling.' },
      {
        id: 4,
        title: 'Rating',
        text: 'I am taking decisions based on my value system.',
      },
      {
        id: 5,
        title: 'Intuitive',
        text: 'I trust my ideas and intuitive inspirations.',
      },
    ],
  },
];
