import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useQuery from '../../hooks/useQuery';

import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';

import { ThemeContext } from '../../context/ThemeContext';
import { QuestionsContext } from '../../context/QuestionsContext';
import { ApproachContext } from '../../context/ApproachContext';

const Introduction = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const id = query.get('id');

  const { backgroundColor, textColor } = useContext(ThemeContext);
  const { questions, setCurrentQuestionIndex } = useContext(QuestionsContext);
  const { approach } = useContext(ApproachContext);

  useEffect(() => {
    if (questions.length === 0) {
      history.push(`/?id=${id}`);
    }
  }, [questions, history, id]);

  return (
    <>
      <Header>
        <h1 className="text-3xl font-bold mb-4">{t(`introduction.title.${approach}`)}</h1>
        <p>{t(`introduction.description.${approach}`)}</p>
      </Header>

      <Content>
        <div className="flex flex-col lg:flex-row flex-wrap">
          <div className="w-full lg:w-1/2">
            <img
              src="/images/animation.gif"
              alt="Question placeholder"
              className="w-full lg:w-10/12"
            />
          </div>
          <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
            <p className="text-base">{t(`introduction.text.${approach}`)}</p>
          </div>

          <div className="w-full mt-8 px-8 lg:px-0 lg:mt-20 ">
            <Button
              fullWidth
              backgroundColor={backgroundColor}
              textColor={textColor}
              onClick={() => {
                setCurrentQuestionIndex(0);
                history.push(`/questions?id=${id}`);
              }}
            >
              {t(`introduction.button.${approach}`)}
            </Button>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Introduction;
