import PropTypes from 'prop-types';

const Content = ({ bgColor, children }) => (
  <main
    style={{
      backgroundColor: bgColor || 'inherit',
    }}
  >
    <div className="max-w-2xl mx-auto py-6 px-8 lg:px-0">{children}</div>
  </main>
);

Content.defaultProps = {
  bgColor: null,
};

Content.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Content;
