import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = React.createContext();

const ThemeStore = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState('#AC0D21');
  const [textColor, setTextColor] = useState('#FFFFFF');

  return (
    <ThemeContext.Provider
      value={{
        backgroundColor,
        textColor,
        setBackgroundColor,
        setTextColor,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ThemeContext, ThemeStore };
