import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from '../components/Spinner';

import Start from '../pages/Start/Start';
import Introduction from '../pages/Introduction/Introduction';
import Questions from '../pages/Questions/Questions';
import Results from '../pages/Results/Results';
import Page404 from '../pages/404/404';

function Root() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path="/" exact component={Start} />
        <Route path="/introduction" exact component={Introduction} />
        <Route path="/questions" exact component={Questions} />
        <Route path="/results" exact component={Results} />
        <Route path="*" component={Page404} />
      </Switch>
    </Suspense>
  );
}

export default Root;
