import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ApproachContext = React.createContext();

const ApproachStore = ({ children }) => {
  const [approach, setApproach] = useState('formal');

  return (
    <ApproachContext.Provider
      value={{
        approach,
        setApproach,
      }}
    >
      {children}
    </ApproachContext.Provider>
  );
};

ApproachStore.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ApproachContext, ApproachStore };
